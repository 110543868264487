// Empty file used when no integration is set
import { BRIDGE_CONFIG_RETRIEVED, BRIDGE_ON_CLICK_TO_CALL, SDK_CLICK_TO_CALL } from '../main/sagas/bridgeSagas';
import { sendMessage } from '../main/utils/integration';
import { getLocalStorageItem } from '../main/utils/localstorage';

import './styles.css';

window.onload = () => {
  if (window !== window.parent) {
    // Do nothing when embedded in an iframe
    return;
  }
  let config = {};
  try {
    config = JSON.parse(getLocalStorageItem('bridgeConfig'));
  } catch (_) {
    // Nothing to do
  }

  // Fake event when no integration is defined
  sendMessage(BRIDGE_CONFIG_RETRIEVED, {
    config: {
      server: config?.server || 'stack.dev.wazo.io',
      language: 'fr',
      domainName: 'xxx',
    },
  });
};

const onMessage = async event => {
  switch (event.data.type) {
    case SDK_CLICK_TO_CALL:
      sendMessage(BRIDGE_ON_CLICK_TO_CALL, { number: event.data.number });
      break;
    default:
      break;
  }
};

window.addEventListener('message', onMessage, false);
